<template>
  <report-template
    :options="options"
    :chartOptions="chartOptions"
    :series="series"
    :selectTab="selectTab"
    :loadReport="fetchReport"
  />
</template>

<script>
import { Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import { differenceInDays, format, startOfYear } from "date-fns";
import { get, sync } from "vuex-pathify";

export default Vue.extend({
  name: "ReportProduce",
  components: {
    ReportTemplate: () => import("@/components/report/ReportTemplate.vue")
  },
  computed: {
    ...sync("report", ["currentTab"]),
    ...mapState({ options: state => state.report.optionsProduce }),
    ...mapState({ series: state => state.report.series }),
    ...mapState({ chartOptions: state => state.report.chartOptions }),
    ...mapState({ dateRange: state => state.report.range })
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      resetReport: "report/resetProduceReport",
      fetchOrganizations: "report/fetchProduceOrganizations",
      fetchReport: "report/fetchProduceData"
    }),
    initialize() {
      this.currentTab = this.options.tabs[0];
      this.selectTab(this.currentTab);
    },
    selectTab(tab) {
      const link = tab.link.toLowerCase();
      this.fetchOrganizations(link);
      this.resetReport(tab);
      this.showDefaultChart(link);
    },
    showDefaultChart(link) {
      const dateFrom = format(startOfYear(Date.now()), "yyyy-MM-dd");
      const dateTo = format(Date.now(), "yyyy-MM-dd");
      const options = {
        path: link,
        params: {
          organizationId: 0,
          date_from: dateFrom,
          date_to: dateTo
        }
      };
      this.fetchReport(options);
    }
  },
  async mounted() {
    // this.selectTab(this.options.tabs[0]);
    this.initialize();
  }
});
</script>
